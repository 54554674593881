<template>
  <div v-loading="isLoading">
    <section class="section-container">
      <div class="head">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item data-cy="todo-tab-complains" index="1">
            Réclamation de cashback <round-badge :value="cashbackComplainsRequests.length"></round-badge>
          </el-menu-item>

          <el-menu-item data-cy="todo-tab-status-update" index="2">
            MAJ statut <round-badge :value="statusUpdateRequests.length"></round-badge>
          </el-menu-item>
        </el-menu>

        <div class="line"></div>
      </div>

      <div class="list">
        <template v-if="list.length > 0" class="list">
          <el-table :data="tableDataPart" empty-text="Pas de clubistes" @sort-change="onSort">
            <el-table-column prop="date" label="Date" sortable="custom" width="128">
              <template slot-scope="scope">
                {{ dateToStr(scope.row.date) }}
              </template>
            </el-table-column>

            <el-table-column prop="cardNum" label="N° de Carte Club" width="180" />

            <el-table-column v-if="activeIndex === '2'" label="Action">
              <template slot-scope="scope">
                {{ statusToStr(scope.row.oldStatus, scope.row.newStatus) }}
              </template>
            </el-table-column>

            <el-table-column align="right">
              <template slot-scope="scope">
                <button
                  data-cy="process-cta"
                  size="small"
                  class="el-button el-button--small el-button--danger"
                  @click="openProcessModal(scope.row)"
                >
                  Traiter
                </button>

                <!-- <Information class="infos" @click.native="openProcessModal(scope.row)" /> -->
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            layout="prev, pager, next"
            :current-page="currentPage"
            :total="list.length"
            :page-size="clubistDisplayCount"
            hide-on-single-page
            @current-change="num => currentPage = num"
            @prev-click="() => currentPage--"
            @next-click="() => currentPage++"
          />
        </template>

        <p v-else class="empty">
          - Aucune demande à traiter -
        </p>
      </div>
    </section>

    <modal-process-cashback-request
      v-if="modalProcessCashbackRequest"
      :complaint="modalProcessCashbackRequest"
      :isOpen="modalProcessCashbackRequest !== null"
      @close="modalProcessCashbackRequest = null"
      @submit="$emit('change')"
    />

    <modal-process-status-request
      v-if="modalProcessStatusRequest"
      :status-request="modalProcessStatusRequest"
      :isOpen="modalProcessStatusRequest !== null"
      @close="modalProcessStatusRequest = null"
      @submit="$emit('change')"
    />
  </div>
</template>

<script>
import ModalProcessCashbackRequest from '@/modals/ProcessCashbackRequest.vue'
import ModalProcessStatusRequest from '@/modals/ProcessStatusRequest.vue'

export default {
  name: 'ClubistRequests',

  components: {
    ModalProcessCashbackRequest,
    ModalProcessStatusRequest
  },

  props: {
    clubistId: {
      type: String
    },

    clubist: {
      type: Object
    },

    isLoading: {
      type: Boolean,
      default: false
    },

    allRequests: {
      type: Array,
      default () { return [] }
    },

    cashbackComplainsRequests: {
      type: Array,
      default () { return [] }
    },

    statusUpdateRequests: {
      type: Array,
      default () { return [] }
    }
  },

  data () {
    return {
      activeIndex: '1',
      currentPage: 1,

      modalProcessCashbackRequest: null,
      modalProcessStatusRequest: null,

      clubistDisplayCount: 10,
      orderByDateAsc: true
    }
  },

  computed: {
    list () {
      let list

      if (parseInt(this.activeIndex, 10) === 1) {
        list = [...this.cashbackComplainsRequests]
      } else if (parseInt(this.activeIndex, 10) === 2) {
        list = [...this.statusUpdateRequests]
      }

      const order = this.orderByDateAsc ? 1 : -1

      list.sort((a, b) => a.date < b.date ? order : -order)

      return list
    },

    tableDataPart () {
      const min = (this.currentPage - 1) * this.clubistDisplayCount
      const max = min + this.clubistDisplayCount
      return this.list.slice(min, max)
    }
  },

  created () {
    this.onSort({ prop: 'date' })
  },

  methods: {
    onSort ({ prop, order = null }) {
      if (prop === 'date') {
        if (order === 'ascending') {
          this.orderByDateAsc = true
        } else if (order === 'descending' || order === null) {
          this.orderByDateAsc = false
        }
      }
    },

    dateToStr (time) {
      const options = { year: '2-digit', month: 'numeric', day: 'numeric' }
      return new Date(time).toLocaleDateString('fr-FR', options)
    },

    singleStatusToStr (status) {
      if (status === 'BANNED') {
        return 'Bloqué'
      }

      if (status === 'WARNED') {
        return 'Averti'
      }

      if (status === 'NORMAL') {
        return 'Normal'
      }
    },

    statusToStr (oldStatus, newStatus) {
      const oldStatusFormatted = this.singleStatusToStr(oldStatus).toLowerCase()
      const newStatusFormatted = this.singleStatusToStr(newStatus).toLowerCase()

      return `Mise à jour de statut : ${oldStatusFormatted} -> ${newStatusFormatted}`
    },

    handleSelect (key, keyPath) {
      this.activeIndex = key
      this.currentPage = 1
    },

    openProcessModal (data) {
      if (parseInt(this.activeIndex, 10) === 1) {
        this.modalProcessCashbackRequest = data
      } else if (parseInt(this.activeIndex, 10) === 2) {
        this.modalProcessStatusRequest = data
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  margin-top: 60px;
  min-height: 200px;
  text-align: center;
}
.head {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.el-menu-demo {
  display: inline-block
}
.empty {
  font-size: 14px;
  opacity: 0.3;
  text-align: center;
}
.infos {
  margin-left: 2em;
}
</style>
