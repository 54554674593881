<template>
  <section class="section-title">
    <el-row type="flex" align="middle">
      <el-col data-cy="search-clubist-back" :span="5">
        <router-link v-if="displaySearch" :to="{ name: 'search' }" class="back-link">Rechercher un clubiste</router-link>
      </el-col>
      <el-col :span="17">
        <h1>
          <slot></slot>
        </h1>
      </el-col>
    </el-row>
  </section>
</template>

<script>
export default {
  props: {
    displaySearch: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.back-link {
  background-image: url('~@/assets/svg/back.svg');
  background-repeat: no-repeat;
  background-position: center left;
  padding-left: 20px;
  text-decoration: none;
}
</style>
