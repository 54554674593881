<template>
  <el-dialog
    :visible="isOpen"
    :title="title"
    :center="true"
    :modalAppendToBody="false"
    :before-close="() => $emit('close')"
    width="730px"
  >
    <el-row>
      <el-col :offset="4" :span="16" class="align-left">
        <p class="title">
          Objet de la réclamation
        </p>

        <p class="comment">
          {{ complaint && complaint.message || '' }}
        </p>

        <el-row v-if="complaint && complaint.file" type="flex" align="middle">
          <button class="file-text" @click="downloadFile({ category: 'CASHBACK_COMPLAIN', file: complaint.file })">
            <i class="el-icon-document file-icon"></i>
            {{ complaint.file.name }}
          </button>
        </el-row>

        <template v-if="complaint && complaint.amount !== undefined && complaint.amount !== null">
          <p class="title">
            Cashback
          </p>

          <p class="comment comment--num">
            {{ complaint.amount }} dhs
          </p>
        </template>

      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer"></span>
  </el-dialog>
</template>

<script>
import DownloadAttachedFile from '@/mixins/DownloadAttachedFile'

export default {
  mixins: [ DownloadAttachedFile ],
  props: {
    title: {
      type: String,
      default: 'Traiter la demande'
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    complaint: {
      type: Object,
      default () {
        return {
          id: '',
          message: '',
          cashback: 0,
          file: null
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  text-align: left;
}

.center {
  text-align: center;
}

.title {
  font-weight: 700;
  color: $dark-grey;
  font-size: 16px;
  margin: 2em 0 0.25em;
}
.infos {
  font-size: 12px;
  opacity: 0.5;
}
.align-left {
  text-align: left;
}
.flex-1 {
  flex: 1;
}
.comment--num {
  padding: 12px 18px;
}
.comment {
  @extend %claim-comment;
}
.file-text {
  @extend %claim-file-text;
}
</style>
