<template>
  <div>
    <section class="section-container">

      <div class="head">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item index="1">En attente <round-badge :value="todoPendingList.length"></round-badge></el-menu-item>
          <el-menu-item index="2">Validées</el-menu-item>
          <el-menu-item index="3">Refusées</el-menu-item>
        </el-menu>

        <div class="line"></div>
      </div>

      <div class="list">
        <template v-if="list.length > 0" class="list">
          <el-table :data="clubistPart" empty-text="Pas de clubistes" @sort-change="onSort">
            <el-table-column prop="date" label="Date" sortable="custom" width="100">
              <template slot-scope="scope">
                {{ dateToStr(scope.row.date) }}
              </template>
            </el-table-column>

            <el-table-column prop="cardNum" label="N° de Carte Club" width="180"></el-table-column>

            <el-table-column prop="lastName" label="Nom"></el-table-column>

            <el-table-column prop="firstName" label="Prénom"></el-table-column>

            <el-table-column align="right" width="100">
              <template slot-scope="scope">
                <router-link :to="{ name: 'clubistProfile', params: { clubistId: scope.row.clubistId } }">
                  Voir le profil
                </router-link>
              </template>
            </el-table-column>

            <el-table-column class="column-flex" align="right" :width="activeIndex === '1' ? '140' : '120'">
              <template slot-scope="scope">
                <button v-if="activeIndex === '1'" size="small" class="el-button el-button--small el-button--danger" @click="modalProcessCashbackRequest = scope.row">
                  Traiter
                </button>

                <Information class="infos" @click.native="modalInfoCashbackRequest = scope.row" />
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            layout="prev, pager, next"
            :current-page="currentPage"
            :total="list.length"
            :page-size="clubistDisplayCount"
            v-if="!isLoading"
            hide-on-single-page
            @current-change="num => currentPage = num"
            @prev-click="() => currentPage--"
            @next-click="() => currentPage++"
          ></el-pagination>
        </template>

        <p v-else class="empty">
          - Aucune demande à traiter -
        </p>
      </div>

    </section>

    <ModalProcessCashbackRequest
      :complaint="modalProcessCashbackRequest"
      :isOpen="modalProcessCashbackRequest !== null"
      @close="modalProcessCashbackRequest = null"
      @submit="$emit('change')"
    />

    <ModalInfoCashbackRequest
      :complaint="modalInfoCashbackRequest"
      :isOpen="modalInfoCashbackRequest !== null"
      @close="modalInfoCashbackRequest = null"
    />

  </div>
</template>

<script>
import ModalProcessCashbackRequest from '@/modals/ProcessCashbackRequest.vue'
import ModalInfoCashbackRequest from '@/modals/InfoCashbackRequest.vue'
import Information from '@/components/Information.vue'

export default {
  components: {
    ModalProcessCashbackRequest,
    ModalInfoCashbackRequest,
    Information
  },
  props: {
    cashbackComplainsRequests: {
      type: Array,
      default () { return [] }
    }
  },
  data () {
    return {
      activeIndex: '1',
      currentPage: 1,
      modalProcessCashbackRequest: null,
      modalInfoCashbackRequest: null,
      isLoading: false,
      clubistDisplayCount: 10,
      orderByDateAsc: true
    }
  },
  watch: {
    cashbackComplainsRequests: 'onSort'
  },
  computed: {
    list () {
      const filterState = ['pending', 'validated', 'refused'][parseInt(this.activeIndex) - 1]
      const filter = ({ state }) => state === filterState
      const list = this.cashbackComplainsRequests.filter(filter)
      const order = this.orderByDateAsc ? 1 : -1
      list.sort((a, b) => a.date < b.date ? order : -order)
      return list
    },
    clubistPart () {
      const min = (this.currentPage - 1) * this.clubistDisplayCount
      const max = min + this.clubistDisplayCount
      return this.list.slice(min, max)
    },
    todoPendingList () {
      return this.cashbackComplainsRequests.filter(({ state }) => state === 'pending')
    }
  },
  methods: {
    onSort ({ prop = 'date', order = null }) {
      if (prop === 'date') {
        if (order === 'ascending') {
          this.orderByDateAsc = true
        } else if (order === 'descending' || order === null) {
          this.orderByDateAsc = false
        }
      }
    },
    dateToStr (time) {
      const options = { year: '2-digit', month: 'numeric', day: 'numeric' }
      return new Date(time).toLocaleDateString('fr-FR', options)
    },
    handleSelect (key, keyPath) {
      this.activeIndex = key
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  margin-top: 60px;
  min-height: 200px;
  text-align: center;
}
.head {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.el-menu-demo {
  display: inline-block
}
.empty {
  font-size: 14px;
  opacity: 0.3;
  text-align: center;
}
.infos {
  margin-left: 2em;
}
.column-flex {
  display: flex;
}
</style>
