<template>
  <el-dialog
    data-cy="process-cashback-request-modal"
    v-loading="isLoading"
    :visible="isOpen"
    title="Traiter la demande"
    :center="true"
    :modalAppendToBody="false"
    :before-close="() => $emit('close')"
    width="730px"
  >
    <el-row>
      <el-col :offset="4" :span="16" class="align-left">
        <p class="title">
          Objet de la réclamation
        </p>

        <p data-cy="comment" class="comment">
          {{ complaint && complaint.message || '' }}
        </p>

        <el-row v-if="complaint && complaint.file" type="flex" align="middle">
          <button data-cy="download-file-cta" class="file-text" @click="downloadFile({ category: 'CASHBACK_COMPLAIN', file: complaint.file })">
            <i class="el-icon-document file-icon"></i>
            {{ complaint.file.name }}
          </button>
        </el-row>

        <el-form
          v-if="state > 1"
          :model="inputs"
          ref="form"
          label-position="top"
          :rules="rules"
          @submit.native.prevent
        >
          <p class="title">
            Ajout de cashback
          </p>

          <el-form-item data-cy="amount-input" prop="cashback" label="">
            <el-input v-model="inputs.cashback" type="number" placeholder="Saisissez un montant">
              <template slot="append">dhs</template>
            </el-input>
          </el-form-item>
        </el-form>

      </el-col>
    </el-row>

    <span slot="footer" class="dialog-footer">
      <template v-if="state === 1">
        <el-button data-cy="cancel" @click="deny()">Refuser</el-button>
        <el-button data-cy="valid" type="primary" @click="accept()">Valider</el-button>
      </template>
      <template v-if="state === 2">
        <el-button data-cy="valid" type="primary" @click="supply()">Alimenter la jauge</el-button>
      </template>
    </span>
  </el-dialog>
</template>

<script>
import { cashbackRule, createRequired } from '@/data/input-rules'
import DownloadAttachedFile from '@/mixins/DownloadAttachedFile'

export default {
  mixins: [ DownloadAttachedFile ],
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    complaint: {
      type: Object,
      default () {
        return {
          id: '',
          message: '',
          file: null
        }
      }
    }
  },
  data () {
    return {
      isLoading: false,

      state: 1,

      rules: {
        cashback: [
          {
            validator: (rule, value, callback) => {
              if (value > 400) {
                callback(new Error('Le montant ne peut pas dépasser 400 dhs'))
              } else {
                callback()
              }
            },
            trigger: ['blur', 'change']
          },
          {
            validator: (rule, value, callback) => {
              if (value <= 0) {
                callback(new Error('Le montant ne peut pas être négatif ou null'))
              } else {
                callback()
              }
            },
            trigger: ['blur', 'change']
          },
          ...cashbackRule,
          createRequired('montant')
        ]
      },

      inputs: {
        cashback: null
      }
    }
  },
  watch: {
    isOpen (isOpen) {
      if (isOpen) {
        // reset data on re open
        this.state = 1
        this.inputs = {
          cashback: null
        }
      }
    }
  },

  methods: {

    accept () {
      this.state = 2
    },

    deny () {
      const payload = {
        id: this.complaint.id,
        clubistId: this.complaint.clubistId,
        isAccepted: false
      }

      this.isLoading = true
      this.$store.dispatch('cashbackComplaintProcess', payload)
        .then(clubist => {
          this.$emit('submit')
          this.$emit('close')
        })
        .catch(error => this.$message.error(this.$t(error.message)))
        .finally(() => {
          this.isLoading = false
        })
    },

    supply () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const payload = {
            id: this.complaint.id,
            clubistId: this.complaint.clubistId,
            amount: Number(this.inputs.cashback),
            isAccepted: true
          }

          this.isLoading = true
          this.$store.dispatch('cashbackComplaintProcess', payload)
            .then(clubist => {
              this.$message({
                message: `Votre demande a bien été prise en compte`,
                type: 'success'
              })

              this.$emit('submit')
              this.$emit('close')
            })
            .catch(error => this.$message.error(this.$t(error.message)))
            .finally(() => {
              this.isLoading = false
            })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  text-align: left;
}

.center {
  text-align: center;
}

.title {
  font-weight: 700;
  color: $dark-grey;
  font-size: 16px;
  margin: 2em 0 0.25em;
}
.infos {
  font-size: 12px;
  opacity: 0.5;
}
.align-left {
  text-align: left;
}
.flex-1 {
  flex: 1;
}
.comment {
  @extend %claim-comment;
}
.file-text {
  @extend %claim-file-text;
}
</style>
