export default {
  name: 'DownloadAttachedFile',

  methods: {
    downloadFile ({ file, category }) {
      const options = {
        category,
        file
      }

      this.isLoading = true
      this.$store.dispatch('file/downloadFile', options)
        .then(() => this.$message({
          message: 'Le fichier ' + file.name + ' a bien été téléchargé',
          type: 'success'
        }))
        .catch(error => {
          this.$message.error(this.$t(error.message))
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
