<template>
  <el-dialog
    v-loading="isLoading"
    title="Traiter la demande"
    width="730px"
    :visible="isOpen"
    :center="true"
    :modalAppendToBody="false"
    :before-close="() => $emit('close')"
    data-cy="claim-block-accept-modal"
  >
    <p class="title">
      Changement de statut
    </p>

    <div class="status-container">
      <div class="status-preview">
        <p>Statut initial</p>

        <div class="status-bubble-container">
          <div class="status-bubble" :class="[getStatusBubbleClass(statusRequest.oldStatus)]"></div>

          <p>{{ getStatusBubbleWording(statusRequest.oldStatus) }}</p>
        </div>
      </div>

      <div class="status-preview">
        <p>Nouveau statut</p>

        <div class="status-bubble-container">
          <div class="status-bubble" :class="[getStatusBubbleClass(statusRequest.newStatus)]"></div>

          <p>{{ getStatusBubbleWording(statusRequest.newStatus) }}</p>
        </div>
      </div>
    </div>

    <p class="title">Déclaration du clubiste</p>

    <div class="description" data-cy="comment">{{ statusRequest.description }}</div>

    <button v-if="statusRequest && statusRequest.file" class="file-text" data-cy="download-file-cta" @click="downloadFile({ category: 'STATUS_MODIFIER', file: statusRequest.file })">
      <i class="el-icon-document file-icon"></i>
      {{ statusRequest.file.name }}
    </button>

    <span slot="footer">
      <el-button
        @click="deny()"
        :loading="isLoading"
        :disabled="isLoading"
        data-cy="cancel-cta"
      >
        Refuser
      </el-button>

      <el-button
        type="primary"
        @click="accept()"
        :loading="isLoading"
        :disabled="isLoading"
        data-cy="submit-cta"
      >
        Valider
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import DownloadAttachedFile from '@/mixins/DownloadAttachedFile'

export default {
  mixins: [ DownloadAttachedFile ],

  name: 'ProcessStatusRequest',

  props: {
    isOpen: {
      type: Boolean,
      default: false
    },

    statusRequest: {
      type: Object,
      default () {
        return {
          clubistId: '',
          statusRequestId: '',
          oldStatus: '',
          newStatus: '',
          description: ''
        }
      }
    }
  },

  data () {
    return {
      isLoading: false
    }
  },

  methods: {
    getStatusBubbleClass (value) {
      if (value === 'NORMAL') {
        return 'green'
      }

      if (value === 'WARNED') {
        return 'orange'
      }

      if (value === 'BANNED') {
        return 'red'
      }
    },

    getStatusBubbleWording (value) {
      if (value === 'NORMAL') {
        return 'Normal'
      }

      if (value === 'WARNED') {
        return 'Averti'
      }

      if (value === 'BANNED') {
        return 'Bloqué'
      }
    },

    accept () {
      const payload = {
        state: 'VALIDATED',
        clubistId: this.statusRequest.clubistId,
        statusRequestId: this.statusRequest.statusRequestId
      }

      this.isLoading = true

      this.$store.dispatch('marketing/editStatusModifier', payload)
        .then(() => {
          this.$emit('close')
          this.$emit('submit')
        })
        .catch(error => this.$message.error(error.message))
        .finally(() => {
          this.isLoading = false
        })
    },

    deny () {
      const payload = {
        state: 'REFUSED',
        clubistId: this.statusRequest.clubistId,
        statusRequestId: this.statusRequest.statusRequestId
      }

      this.isLoading = true

      this.$store.dispatch('marketing/editStatusModifier', payload)
        .then(() => {
          this.$emit('close')
          this.$emit('submit')
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-left: 76px;
  color: #3E3E3E;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  width: 401px;
  text-align: left;
}

.status-bubble {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 16px;

  &.green {
    background-color: #52E02F;
  }

  &.orange {
    background-color: #FF9552;
  }

  &.red {
    background-color: #DF0D36;
  }
}

.status-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;

  .status-preview {
    margin: 0 16px;

    & > p {
      color: #3E3E3E;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      text-align: center;
    }

    .status-bubble-container {
      display: flex;

      & > p {
        color: #000000;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        text-align: left;
      }
    }
  }
}

.file-text {
  @extend %claim-file-text;
  width: 530px;
  margin: 25px auto 0;
  text-align: left;
  display: inline-block;
}

.description {
  background-color: #FFFFFF;
  border: 1px solid #DFE1E5;
  border-radius: 4px;
  width: 530px;
  height: 103px;
  margin: 0 auto;
  padding: 16px;
  color: #3E3E3E;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}
</style>
