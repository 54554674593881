<template>
  <section class="section-clubistes">
    <el-menu class="menu-left menu-left--large" :default-active="$route.name" @select="fixFastClick">
      <el-menu-item v-if="displayMenu" index="cCRequestsCashbackClaim" class="first-el-menu-item">
        <router-link :to="{ name: 'cCRequestsCashbackClaim' }" class="link flex">
          Réclamation de Cashback <round-badge :value="todoPendingList.length"></round-badge>
        </router-link>
      </el-menu-item>

      <el-menu-item v-if="displayMenu && $store.getters.isMarketing" index="cCStatusRequests">
        <router-link :to="{ name: 'cCStatusRequests' }" class="link flex">
          MAJ Statut <round-badge :value="todoStatusPendingList.length"></round-badge>
        </router-link>
      </el-menu-item>
    </el-menu>

    <div class="slot">
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    displayMenu: {
      type: Boolean,
      default: true
    },

    cashbackComplainsRequests: {
      type: Array,
      default () {
        return []
      }
    },

    statusUpdateRequests: {
      type: Array,
      default () {
        return []
      }
    }
  },

  computed: {
    todoPendingList () {
      return this.cashbackComplainsRequests.filter(({ state }) => state === 'pending')
    },

    todoStatusPendingList () {
      return this.statusUpdateRequests.filter(({ state }) => state === 'pending')
    }
  },

  methods: {
    // If you click faster the route don't change
    // This fix add a test (from menu) to force route to change
    fixFastClick (name) {
      this.$nextTick(() => {
        if (name !== this.$route.name) {
          this.$router.push({ name })
            .catch(error => console.error(error.message))
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.link {
  text-decoration: none;
}

.flex {
  display: flex;
}

.section-clubistes {
  display: flex;

  .slot {
    flex: 1;
  }
}
</style>
