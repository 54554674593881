<template>
  <div class="informations-page page">
    <div class="container">
      <ClubistHead :displaySearch="false">
        Demandes Call Center
      </ClubistHead>

      <CCRequestsBody
        v-loading="isLoading"
        :display-menu="true"
        :cashback-complains-requests="cashbackComplainsRequests"
        :status-update-requests="statusUpdateRequests"
      >
        <router-view
          :cashback-complains-requests="cashbackComplainsRequests"
          :status-update-requests="statusUpdateRequests"
          @change="update"
        />
      </CCRequestsBody>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ClubistHead from '@/components/ClubistHead.vue'
import CCRequestsBody from '@/components/CCRequestsBody.vue'

export default {
  components: {
    ClubistHead,
    CCRequestsBody
  },

  computed: {
    ...mapGetters({
      isLoading: 'marketing/isLoading',

      cashbackComplainsRequests: 'marketing/cashbackComplainsRequests',
      statusUpdateRequests: 'marketing/statusUpdateRequests'
    })
  },

  created () {
    this.update()
  },

  beforeDestroy () {
    this.$store.commit('marketing/removeCashbackComplainsRequests')
    this.$store.commit('marketing/removeStatusUpdateRequests')
  },

  methods: {
    update () {
      this.$store.dispatch('marketing/loadCashbackComplainsRequests', {})
      this.$store.dispatch('marketing/loadStatusUpdateRequests', {})
    }
  }
}
</script>

<style lang="scss" scoped>
.informations-page {
  .el-form {
    margin-bottom: 25px;
  }
}
.el-col-separator {
  border-right: 1px solid #d8d8d8;
}
.clubist-status {
  color: $blue;
  font-weight: bold;
}
.switch {
  margin-top: 1em;
  margin-bottom: 3em;
}
</style>
